<template>
  <div class="header">
    <div class="logo">
        <img class="logoImg" :src="img1">
    </div>
    <div class="view">
      <div class="login">
        <div class="test" @click="select('login')">登录</div>
      </div>
      <div class="register">
        <div class="test" @click="select('read')">注册</div>
      </div>
    </div>
    <div class="more" @click="select('more')">
      <img :src="img2">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      img1: require('@/assets/img/logo有色备份@2x.png'),
      img2: require('@/assets/img/更多.png')
    }
  },
  methods: {
    select (a) {
      this.$router.push({
        path: `/${a}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transform:translateZ(10);
  line-height: 0.42rem;
  height: 0.42rem;
  background-color: rgba(255, 255, 255, 1)
}
.logo {
  padding: 0.08rem 0.135rem 0.1rem 0.08rem;
}
.logoImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.view {
  display: flex;
  align-items: center;
  margin-top: 0.07rem;
  margin-right: 0.825rem;
  width: 1.4rem;
  height: 0.28rem;
}
.login {
  width: 0.7rem;
  color: #3974FB;
  border: 0.01rem solid rgba(57, 116, 251, 1);
  border-radius: 0.2rem 0 0 0.2rem;
  font-family: PingFangSC-Regular;
}
.register {
  width: 0.69rem;
  border: 0.01rem solid #d2d2d2;
  border-radius: 0 0.2rem 0.2rem 0;
  font-family: PingFangSC-Regular;
}
.test {
  line-height: 0.28rem;
  text-align: center;
}
.more {
  width: 0.185rem;
  margin-top: 0.04rem;
  margin-right: 0.15rem;
}
.img {
  width: 100%;
}
</style>
