<template>
  <div class="wrapper">
    <div class="aside">
      <div class="x" @click="back">
        <img class="x_img" :src="x">
      </div>
      <div class="home" @click="goToHome">首页</div>
      <div class="product">产品</div>
      <div class="forms1">
        <div class="form1" v-for="(form,index) in forms1" :key="index">
          <div class="border"></div>
          <div class="content" @click="select(form.id)">{{form.title}}</div>
        </div>
      </div>
      <div class="product">解决方案</div>
      <div class="forms1" v-for="(form,index) in forms2" :key="index">
        <div class="form1">
          <div class="border"></div>
          <div class="content">{{form}}</div>
        </div>
      </div>
      <div class="product" @click="select('10')">客户案例</div>
      <div class="product" @click="select('9')">关于我们</div>
    </div>
    <div class="mock" @click="back"></div>
  </div>
</template>

<script>
export default {
  name: 'more',
  data () {
    return {
      x: require('@/assets/img/X.png'),
      forms1: [{
        id: '8',
        img: require('@/assets/img/form1@2x.png'),
        title: '品牌商城系统',
        text: '品牌商城类小程序/APP定制开发，特价支持'
      }, {
        id: '7',
        img: require('@/assets/img/form2@2x.png'),
        title: '连锁门店系统',
        text: '连锁门店小程序/APP定制开发，仅仅1.98万起'
      }, {
        id: '6',
        img: require('@/assets/img/form3@2x.png'),
        title: '行业一码通',
        text: '行业一码通定制，所有对外服务窗口'
      }, {
        id: '5',
        img: require('@/assets/img/form4@2x.png'),
        title: '酒店管理系统',
        text: '系统定制开发，多语言版本，支持连锁酒店'
      }, {
        id: '4',
        img: require('@/assets/img/form5@2x.png'),
        title: '智能终端采集分析系统',
        text: '本软件为一个针对智能终端的各类数据'
      }, {
        id: '3',
        img: require('@/assets/img/form6@2x.png'),
        title: '劳务众包用工管理系统',
        text: '实现以灵活用工模式进行统一管理'
      }, {
        id: '2',
        img: require('@/assets/img/form7@2x.png'),
        title: '行业ERP定制开发',
        text: '支持100人以上远程协同办公定制价格19.8万起'
      }, {
        id: '1',
        img: require('@/assets/img/form8@2x.png'),
        title: '小程序/APP/WEB定制开发',
        text: '按需定制，价格透明'
      }],
      forms2: ['数字企业法人', '智慧远程协同办公方案', '多渠道品牌营销解决方案']
    }
  },
  methods: {
    select (id) {
      this.$router.push({
        path: `/page/${id}`
      })
    },
    goToHome () {
      this.$router.push('/')
    },
    back () {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  overflow: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
}
.img {
  width: 100%
}
.border {
  width: 0.04rem;
  height: 0.04rem;
  border: 0.01rem solid #3974FB;
  margin-right: 0.06rem;
}
.aside {
  width: 2.79rem;
  height: 6.895rem;
  background-color: #ffffff;
  .x {
    padding: 0.2rem 0 0.16rem 0.16rem;
    .x_img {
      width: 0.13rem;
    }
  }
  .home {
    font-size: 17px;
    font-family: PingFangSC-Regular;
    color: #3974FB;
    margin: 0 0 0.24rem 0.3rem;
  }
  .product {
    font-size: 17px;
    font-family: PingFangSC-Regular;
    color: #666666;
    margin: 0 0 0.16rem 0.3rem;
  }
  .forms1 {
    margin-left: 0.4rem;
    .form1 {
      display: flex;
      align-items: center;
      margin-bottom: 0.16rem;
      .content {
        font-size: 15px;
        color: #333333;
        font-family: PingFangSC-Regular;
      }
    }
  }
}
.mock {
  width: 0.96rem;
  height: 6.895rem;
  background-color: #7f7f7f;
}

</style>
