<template>
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="title">{{data.title}}</div>
      <div class="content">{{data.case_content}}</div>
      <div class="pic1">
        <img class="img1" :src="data.case_img1">
      </div>
      <div class="text">扫码立即查看</div>
      <div class="pic2">
        <img class="img2" :src="data.case_img2">
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/header.vue'

export default {
  name: 'case',
  components: {
    Header
  },
  data () {
    return {
      data: JSON.parse(this.$route.query.data)
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: absolute;
  overflow: scroll;
  top: 0.5rem;
  left: 0;
  bottom: 0.22rem;
  width: 100%;
}
.title {
  margin: 0.15rem 0 0.13rem 0;
  font-size: 16px;
  text-align: center;
  font-family: PingFangSC-Medium
}
.content {
  margin: 0 0.24rem 0.14rem 0.24rem;
  font-family: PingFangSC-Regular;
}
.pic1 {
  margin: 0 1.07rem 0.13rem 1.07rem;
  border-radius: 0.07rem;
  border: 0.005rem solid rgba(212, 207, 207, 1);
  .img1 {
    width: 1.61rem;
    height: 1.61rem;
  }
}
.text {
  text-align: center;
  font-family: PingFangSC-Regular;
  color: #333333;
  margin-bottom: 0.33rem;
}
.pic2 {
  margin-left: 0.375rem;
  .img2 {
    width: 3rem;
  }
}
</style>
