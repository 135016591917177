<template>
    <div class="wrapper">
       <div class="logo">
           <img src="..\assets\img\logo有色备份@2x.png">
       </div>
       <div class="wrapper__form1">
         <div class="phone">
           <img src="..\assets\img\手机号.png">
         </div>
         <div class="wrapper__input">
            <input class="wrapper__input__content"
              placeholder="请输入手机号"
              type="text"
              v-model="data.phoneNumber"
              maxlength="11"
            >
         </div>
       </div>
       <div class="form1_solid"></div>
       <div class="wrapper__form2">
         <div class="lock">
           <img src="..\assets\img\开锁换锁.png">
         </div>
         <div class="wrapper__input">
            <input class="wrapper__input__content"
               placeholder="请输入密码"
               type="password"
               v-model="data.password"
              >
         </div>
       </div>
       <div class="form2_solid"></div>
       <div class="wrapper-active">
         <div class="check"  @click="handleAutoLogin">
           <img :src=showImg>
         </div>
         <div class="active-content">下次自动登录</div>
         <div class="active-ver" @click="select('verification')">验证码登录</div>
       </div>
        <div class="wrapper__button" @click="handleSubmit"
         :style="{'backgroundColor': buttonType ? '#3974FB' : '#A7BFF5'}"
        >登录</div>
        <div class="wrapper__register" @click="select('read')">新用户注册</div>
    </div>
</template>

<script>
import { reactive, ref, computed, onMounted } from 'vue'
import { postForm } from '../service/base'
import { useRouter } from 'vue-router'
import Cookie from 'js-cookie'

export default {
  name: 'Login',
  setup () {
    const showCheck = ref(true)
    const router = useRouter()
    const data = reactive({ phoneNumber: '', password: '' })

    // 定义按钮颜色样式
    const buttonType = computed(() => {
      return !!(data.phoneNumber.length === 11 && data.password)
    })

    // 页面渲染前读取缓存
    onMounted(() => {
      getCookie()
    })

    // 页面跳转
    const select = (a) => { router.push(a) }

    // 按钮勾选样式
    const showImg = computed(() => {
      return showCheck.value ? require('@/assets/img/选中.png') : require('@/assets/img/未选中.png')
    })

    // 控制是否自动登录
    const handleAutoLogin = () => {
      showCheck.value = !showCheck.value
    }

    // 提交登录信息
    const handleSubmit = async () => {
      try {
        var formData = new FormData()
        formData.append('account', data.phoneNumber)
        formData.append('password', data.password)
        formData.append('loginType', 2)
        const result = await postForm('/home/freeuser/login', formData)
        if (data.phoneNumber && data.password) {
          if (result.data.code === 200) {
            alert(result.data.message)
            if (showCheck.value) {
              setCookie()
            } else {
              delCookie()
              data.phoneNumber = ''
              data.password = ''
            }
            select('/')
          } else {
            alert(result.data.message)
          }
        } else {
          alert('必填项不能为空')
        }
      } catch (e) {
        console.log(e)
      }
    }

    // 缓存数据
    const setCookie = () => {
      Cookie.set('account', data.phoneNumber)
      Cookie.set('password', data.password)
    }

    // 删除缓存
    const delCookie = () => {
      Cookie.remove('account')
      Cookie.remove('password')
    }
    // 读取缓存
    const getCookie = () => {
      const cookieAccount = Cookie.get('account')
      const cookiePassword = Cookie.get('password')
      if (cookieAccount && cookiePassword) {
        data.phoneNumber = cookieAccount
        data.password = cookiePassword
      }
    }

    return {
      data,
      showImg,
      handleAutoLogin,
      handleSubmit,
      select,
      buttonType
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.logo {
  text-align: center;
  width: 1.5rem;
  margin: 0.4rem auto 0.65rem auto;
}
img {
  width: 100%;
}
.wrapper__form1 {
  display: flex;
  line-height: 0.22rem;
  margin: 0 0.2375rem 0 0.2375rem;
  .phone {
  margin-top: 0.02rem;
  width: 0.1192rem;
  }
}
.wrapper__form2 {
  display: flex;
  line-height: 0.22rem;
  margin: 0 0.2375rem 0 0.2375rem;
  .lock {
  margin-top: 0.02rem;
  width: 0.115rem;
  }
}
.form1_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.12rem 0 0.13rem 0.2375rem;
}
.form2_solid {
  width: 3.275rem;
  height: 0.005rem;
  border: 0.005rem solid rgba(233, 233, 233, 1);
  margin: 0.14rem 0 0.13rem 0.2rem;
}
.wrapper__input {
    margin-left: 0.12rem;
    flex: 1;
    box-sizing: border-box;
}
.wrapper__input__content {
    font-size: 0.16rem;
    width: 100%;
    border: none;
    outline: none;
    background: none;
}
.wrapper-active {
  display: flex;
  align-items: center;
  margin-left: 0.24rem;
  .check {
    padding-right: 0.15rem;
    width: 0.16rem;
    padding-top: 0.03rem;
  }
  .active-content {
    font-size: 0.16rem;
    font-family: PingFangSC-Regular;
    color: #333333;
    margin-right: 1.35rem;
  }
  .active-ver {
    font-size: 0.13rem;
    color: #3974FB;
  }
}
.wrapper__button {
  line-height: 0.4rem;
  margin: 0.29rem 0.2rem 0.16rem 0.2rem;
  border-radius: 0.04rem;
  font-size: 0.14rem;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #ffffff;
}
.wrapper__register{
  text-align: center;
  font-size: 0.14rem;
  font-family: PingFangSC-Regular;
  color: #3974FB;
}
::-webkit-input-placeholder {
      color: #CFCFCF
    }
</style>
